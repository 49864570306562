import styles from './Popover.module.scss';
import React, { useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popper as ReactPopper } from 'react-popper';

const Popover = ({
  children,
  closeable = true,
  show,
  onExit,
  target,
  showOverlay = false,
  placement = 'auto',
  hideArrow = true,
}) => {
  const baseRef = useRef(null);
  const [referenceElement, setReferenceElement] = React.useState(null);

  const containerElement = document && document.body;

  const closeOnBackdrop = e => {
    closeable && e.target === e.currentTarget && onExit();
  };
/* istanbul ignore next */ 
  const escExit = useCallback(event => {
    if(event.keyCode === 27) {
      onExit();
    }
  }, []);

  useEffect(() => {
    /* istanbul ignore next */ 
    if (target) {
      const element = document.querySelectorAll(`#${target}`);
      if (element && element.length > 0)
        setReferenceElement(element[0]);
    }
  }, [referenceElement]);

  /* istanbul ignore next */ 
  useEffect(() => {
      document.addEventListener('keydown', escExit, false);
    return () => {
      document.removeEventListener('keydown', escExit, false);
    };
  }, []);

  const extendedModifiers = [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [0, 3],
      },
    },
  ];

  const arrowProps = [
    {
      name: 'arrow',
      enabled: true,
      options: {
        padding: 5,
      },
    }];
  return ReactDOM.createPortal(
    <div ref={baseRef}
      id="popover-base"
      className={classNames('popover-base', styles.popoverBase, showOverlay ? styles.popoverOverlay : null)}
      onMouseDown={closeOnBackdrop}
    >
      <ReactPopper
        arrowProps={arrowProps}
        referenceElement={referenceElement}
        modifiers={extendedModifiers}
        placement={placement}
      >
        {({ ref, style, placement, arrowProps }) => {

          return (
            <div ref={ref} id="popover" style={style} className={classNames(styles.popover)} x-placement={placement}>
              <div id="popover-inner" role="tooltip">
                <div id="popover-header">
                  <div id="popover-close" className={classNames(styles.btnClose)} onClick={onExit} />
                </div>
                <div id="popover-body" className={styles.popoverBody}>
                  {children}
                </div>
              </div>
              {!hideArrow && (
                <span ref={arrowProps.ref} className={classNames(styles.arrow, styles[`arrow-placement-${placement}`])} style={arrowProps.style} />
              )}
            </div>
          );
        }}
      </ReactPopper>
    </div>,
    containerElement,
  );
};

Popover.propTypes = {
  children: PropTypes.node,
  closeable: PropTypes.bool,
  show: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  onExit: PropTypes.func,
  target: PropTypes.string,
  showOverlay: PropTypes.bool,
  placement: PropTypes.string,
  hideArrow: PropTypes.bool,
};

export default Popover;