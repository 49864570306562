import React from 'react';
import PropTypes from 'prop-types';
import styles from '../LookBook.module.scss';
import Popover from '../popover';
import ProductTile from '../productTile/ProductTile';
import { generateRandomNumber } from '../../utils';

const Hotspot = ({
  initialModel,
  index,
  hotspot,
  icon,
  activeIcon,
  setProductIndex,
  productIndex,
  noImage,
}) => {
 
  const { productViewType } = initialModel;
  const blockId = initialModel.id === undefined ? generateRandomNumber() : initialModel.id;
  return (
    <>
      <img
        src={productViewType === 'exploded' && index === productIndex ? activeIcon : icon}
        id={`hotspot-block-${blockId}-${index}`}
        className={styles.hotspotIcon}
        onClick={() => {
          setProductIndex(index);
        }} alt="hotspot"
      />

      { productViewType === 'tile' &&
        hotspot.product &&
        index === productIndex ? (
          <Popover
            show={productIndex}
            target={`hotspot-block-${blockId}-${index}`}
            closeable
            showOverlay={false}
            onExit={() => setProductIndex(false)}
            hideArrow={false}
            selectedProduct={hotspot.product}
          >
            <ProductTile
              key={index}
              model={hotspot.product}
              styleName="popup"
              styles={styles}
              defulatImage={noImage}
            />
          </Popover>
        ) : null}
    </>
  );
};

Hotspot.propTypes = {
  initialModel: PropTypes.object,
  hotspot: PropTypes.object,
  icon: PropTypes.string,
};
export default Hotspot;
