import React, { useCallback, useState, useEffect, useRef } from 'react';
import { connect, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LazyContent } from '../utils/lazyLoader';
import { UseSanaTexts } from 'sana/texts';
import { Row, Col } from 'sana/elements';
import {
  useLoadEffect,
  useHasAbilities,
  useProductDefaultImages,
} from 'sana/utils';
import { AbilityTo } from 'sana/constants';
import { requestProductSet } from '../behavior';
import { getProductIds } from '../utils';
import { TextKeys } from '../utils/textKeysConstants';
import ExplodedView from './exploded/ExplodedView';
import Hotspot from './hotspot/Hotspots';
import styles from './LookBook.module.scss';

const LookBook = ({
  model,
  id,
  products,
  expired,
  requestProductSet,
}) => {
  const { medium } = useProductDefaultImages();
  const [productIndex, setProductIndex] = useState(false);
  const { hotspots, showHotspotAnimation, imageAlternativeText, productViewType } = model;
  const [isProductsAvailable, setProductsAvailable] = useState(false);

  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  const [getHostspots, setHostspots] = useState(undefined);

  const modelRef = useRef();
  let modelExpired = false;
  if (modelRef.current && !shallowEqual(modelRef.current, model))
    modelExpired = true;

  modelRef.current = model;
  const requestProducts = useCallback(productSetValue => {
    const ids = getProductIds(hotspots);
    const options = {
      ids,
      page: { size:ids.length },
    };
    setHostspots(undefined);
    requestProductSet(id, productSetValue, options);
  }, [model.hotspots]);

  useLoadEffect(() => {
    if (!canViewCatalog)
      return;

    if (!products || expired || modelExpired) {
      requestProducts(model.productSet);
    }

  }, [expired, modelExpired, canViewCatalog, model.hotspots]);

  useEffect(() => {
    const uniqProduct = [];
    model.hotspots.forEach(item => {
      const i = uniqProduct.findIndex(x => x.product.id === item.product.id);
      if (i <= -1) {
        uniqProduct.push(item);
      }
    });
    if( products && products.length  === uniqProduct.length ) {
      model.hotspots.forEach(hotspot => {
        products.forEach(product => {
          if (hotspot.product.id === product.id) {
            hotspot.productId = hotspot.product.id;
            hotspot.product = product;
          }
        });
      });
      setProductsAvailable(true);
      setHostspots(model.hotspots);
    }

  }, [products, isProductsAvailable, model, expired, model.hotspots]);
  
  return (
    <> 
      <Row className={classNames(`lookbook-content-block-${model.id}`,  'lookbook-content-block')}>
        <Col>
        
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <LazyContent 
                className={styles.imageLoader} 
                wait={getHostspots  ? 1 : undefined}
              >
                <div className={classNames(styles.imageWrapper, !isProductsAvailable && styles.imageColor)}>
                    <UseSanaTexts textKeys={TextKeys}>{text => (
                      <img 
                        className={styles.image} 
                        src={model.imagePath} 
                        alt={imageAlternativeText ? imageAlternativeText : text[0]} 
                        onClick={()=>{
                          const $element = document.getElementById('popover-close');
                          if($element) {
                            $element.click();
                          }
                        }}
                      />
                    )}
                    </UseSanaTexts>
                  {
                    isProductsAvailable && getHostspots && getHostspots.map((hotspot, index) => {
                      const Id = `item-${model.id}-${hotspot.productId}-${index}`;
                      return (
                        <div
                          key={Id}
                          className={classNames('hotspot', styles.hotspot, showHotspotAnimation === true && styles.hotspotAnimation)}
                          style={{ top: `${hotspot.top}%`, left: `${hotspot.left}%` }}
                        >
                          <Hotspot
                            icon={model.iconPath}
                            activeIcon={model.activeIconPath}
                            productViewType={productViewType}
                            hotspot={hotspot}
                            hotspots={getHostspots}
                            initialModel={model}
                            index={index}
                            setProductIndex={setProductIndex}
                            productIndex={productIndex}
                            keyId={Id}
                            noImage={medium} 
                          />
                        </div>
                      );
                    })
                  }
                </div>
              </LazyContent>
              {isProductsAvailable && productViewType === 'exploded' &&
                <ExplodedView
                  hotspots={getHostspots}
                  styles={styles}
                  blockId={model.id}
                  productIndex={productIndex}
                  noImage={medium} 
                />
              }
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
LookBook.propTypes = {
  model: PropTypes.object,
  id: PropTypes.string.isRequired,
  products: PropTypes.array,
  expired: PropTypes.bool,
  requestProductSet: PropTypes.func.isRequired,
};
const mapStateToProps = ({ products }, { id }) => {
  const getProduct = products && products[id];
  if (!getProduct)
  return { expired: undefined };

  return {
    products:getProduct.products,
    expired: getProduct.expired,
  };
};

export default connect(mapStateToProps, { requestProductSet })(LookBook);