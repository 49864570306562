import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './ProductTitle.module.scss';
import { Link, ProductPrice, ProductOrderButton, ResponsiveLazyImage } from 'sana/elements';
import { productPage } from 'sana/routes';
import classNames from 'classnames';

const ProductTile = ({ model, styleName, defulatImage }) => {

    /* istanbul ignore next */
    const to = useCallback(() => productPage.bind(null, model.id), [model.id]);
    const isConfigurable =
      model.productConfiguratorInfo &&
      model.productConfiguratorInfo.isProductConfigurable;
    const productImage =
      model.image && model.image.medium ? model.image.medium : defulatImage;

  return (
    <div className={classNames('product-tile', styles.productTile, styleName === 'popup' ? styles.popup : styles.explode)}>
      <div className={styles.productInner}>
        <Link url={model.url} to={to}>
          <ResponsiveLazyImage
            alt={model.title}
            src={productImage}
            title={model.title}
          />
        </Link>
      </div>
      <div className={styles.productDetail}>
        <div className={styles.productDescription}>
          <Link url={model.url} to={to}>
            <h4>{model.title}</h4>
          </Link>
          <div className={styles.itemNumber}>Item No. {model.id}</div>
        </div>
        <div className={styles.checkout}>
          <span className={styles.prices}>
            <ProductPrice salesPrice={model.price}  basePrice={model.listPrice} />
          </span>
          <ProductOrderButton
            productId={model.id}
            url={model.url}
            to={to}
            uom={model.uom}
            isOrderable={model.isOrderable}
            isConfigurable={isConfigurable}
            hasVariants={model.hasVariants}
          />
        </div>
      </div>
    </div>
  );
};

ProductTile.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    Price: PropTypes.number,
  }),
  styleName: PropTypes.string,
  defulatImage: PropTypes.string,
};

export default ProductTile;
