import { PRODUCT_SET_RECEIVED } from './actions';
import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'sana/events';

const initialState = {
  products: {},
};
const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

    case PRODUCT_SET_RECEIVED:
        const { contentBlockId, products } = payload;
        return {
            ...state,
            products: {
                ...state.products,
                [contentBlockId]: products,
            },
        };

    case VIEWER_CHANGED:
        return onViewerChanged(state);

    case LANGUAGE_CHANGED:
        return onLanguageChanged(state);

    default:
      return state;
  }
  /* istanbul ignore next */
  function onViewerChanged(state) {
    return { ...state, blocks: undefined };
  }
  /* istanbul ignore next */
  function onLanguageChanged(state) {
    if (!state.products)
        return state;

    return {
        ...state,
        products: Object.entries(state.products).reduce((products, [key, value]) => {
          products[key] = { ...value, expired: true };
            return products;
        }, {}),
    };
  }
};
export default reducer;
