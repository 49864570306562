import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ProductTile from '../productTile/ProductTile';

const ExplodedView = ({ hotspots, styles, blockId, productIndex, noImage }) => {
  const itemRefs = {};

  useEffect(() => {
    if (productIndex !== false) {
      itemRefs[
        `item-${blockId}-${hotspots[productIndex].productId}`
      ].scrollIntoView({
        behavior: 'smooth', 
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [productIndex]);

  function renderList(hotspots, productIndex, styles) {
    const $element = [];
    if (hotspots && Object.keys(hotspots).length > 0) {
      const uniqProduct = [];
      hotspots.forEach(item => {
        const i = uniqProduct.findIndex(x => x.productId === item.productId);
        if (i <= -1) {
          uniqProduct.push(item);
        }
      });
      uniqProduct.map(hotspot => {
        const Id = `item-${blockId}-${hotspot.productId}`;
        $element.push(
          <li
            key={Id}
            ref={el => (itemRefs[Id] = el)}
            className={classNames(
              'item-product',
              styles.product,
              Id,
              productIndex !== false && hotspots[productIndex].productId === hotspot.productId && styles.selected,
            )}
          >
            <ProductTile 
              model={hotspot.product} 
              styleName="explode"
              defulatImage={noImage} 
            />
          </li>,
        );
        return true;
      });
    }
    return $element;
  }

  return (
    <>
      <ul className={classNames(styles.productList, 'product-list')} id={`${blockId}-productlist`}>
        {renderList(hotspots, productIndex, styles)}
      </ul>
    </>
  );
};

ExplodedView.propTypes = {
  hotspots: PropTypes.array,
  styles: PropTypes.object,
  blockId: PropTypes.string,
};
export default ExplodedView;
